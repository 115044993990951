:root {
  // Color Palette
  --jaune: #ffcd00;
  --bleu-primaire: #1c2463;
  --bleu-fonce: #071a39;
  --bleu-moyen: #dde8fb;
  --blanc: #fff;
  --bleu: #5273a7;
  --bleu-clair: #f5f7fb;
  --bleu-300:#395179;
  --gris : #393939;
  --noir : #1C1C1C;
  
  // Colors applications
  --color-bg: var(--blanc);
  --color-text: var(--bleu-fonce);

  // Font sizes
  --font-size-headline: 3rem;   // 48px
  --fontsize-title-1: 2.875rem; // 46px
  --fontsize-title-2: 2.375rem; // 38px
  --fontsize-title-3: 1.875rem; // 30px
  --fontsize-title-4: 1.375rem; // 22px
  --fontsize-text-1: 1.25rem;   // 20px
  --fontsize-text-2: 1.125rem;  // 18px
  --fontsize-body-1: 1rem;      // 16px
  --fontsize-body-2: 0.875rem;  // 14px

  // Spacing
  --spacing-l: 16px;
  --spacing-m: 12px;
  --spacing-s: 8px;
  --spacing-xs: 4px;

  // grid gutters
  --gutter-l: 24px;
  --gutter-s: 16px;

  // Autres
  --border-radius-m: 8px;
  --border-radius-s: 4px;
  --box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 4px 5px 0 rgba(0, 0, 0, 0.14);
}